import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const TextArea = styled.div`
  margin: 2rem 10rem;
  display: grid;
  grid-gap: 20px;
  white-space: pre-wrap;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  @media ${device.tablet} {
    margin: 0.2rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`
const Header3 = styled.h3`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #a35dbc;
  line-height: 1;
  @media ${device.tablet} {
    font-size: 25px;
  }
`

const RowContainer = props => {
  return (
    <>
      <Header3>{props.title}</Header3>
      <TextArea>{props.children}</TextArea>
    </>
  )
}

export default RowContainer
