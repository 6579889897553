import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const LinkButton = styled.button`
  padding: 1rem 2rem;
  color: white;
  font-size: 18px;
  background-color: #088b8c;
  border-radius: 20px;
  border: none;
  outline: none;
  :hover {
    background-color: #055c5d;
  }
`

const Button = props => {
  return (
    <div>
      <Link to={props.link}>
        <LinkButton>{props.title}</LinkButton>
      </Link>
    </div>
  )
}

export default Button
