import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import OperatorImage from "../images/OperatorImage"
import { OutboundLink } from "gatsby-plugin-gtag"
import { createRedirectUrl } from "../../utils/redirectHelpers/modifyUrlHelpers"

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  padding: 25px;
  border-radius: 20px;
  margin: 1rem 5rem;
  background-color: white;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  border: ${props => (props.border ? "1px solid #dadada" : "")};
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    background-color: white;
    padding: 25px 10px;
    margin: 1rem 0.4rem;
  }
`

const OrderButton = styled.button`
  padding: 1rem 2rem;
  background-color: #f66000;
  border: none;
  border-radius: 20px;
  outline: none;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  :hover {
    background-color: violet;
  }
`

const CardItem = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  margin: 1rem;
  @media ${device.tablet} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0.2rem 1rem;
  }
`

const PlanItem = styled.div`
  display: flex;
  flex-direction: column;
`

const BoldFont = styled.div`
  font-size: 24px;
  font-weight: 800;
  text-transform: lowercase;
  margin-bottom: 1rem;
`

const YearSave = styled.div`
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: lowercase;
  margin-bottom: 1rem;
`

const LightFont = styled.div`
  font-size: 0.8rem;
  font-weight: 300;
  color: black;
  text-transform: lowercase;
`

const SaveItems = styled.div`
  display: grid;
  color: ${props => (props.expensive ? "#f53f5f" : "#08BD92")};
  font-size: 0.8rem;
  text-transform: lowercase;
  font-weight: 800;
`
const SaveText = styled.div`
  color: black;
`

const PriceTag = styled.span`
  font-size: 14px;
  text-transform: lowercase;
  font-weight: 300;
`

const PlanName = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
`
const Link = styled(OutboundLink)`
  text-decoration: none;
  color: black;
`

const ResultCard = props => {
  return (
    <Link
      href={createRedirectUrl(props.pageName, props.name)}
      target="blank"
      rel="nofollow"
    >
      <Card border={props.border}>
        <PlanItem>
          <OperatorImage imgName={props.img} />
          <PlanName>{props.name}</PlanName>
          <LightFont>Aloitusmaksu {props.openFee} €</LightFont>
        </PlanItem>
        <CardItem>
          <BoldFont>
            {props.price}€ <PriceTag>/kk</PriceTag>
          </BoldFont>

          <LightFont>Hinta</LightFont>
        </CardItem>
        <CardItem>
          <BoldFont>
            {props.calls}{" "}
            {props.texts.includes("rajaton") ? "" : <PriceTag> €/min</PriceTag>}
          </BoldFont>
          <LightFont>Puhe</LightFont>
        </CardItem>
        <CardItem>
          <BoldFont>
            {props.texts}{" "}
            {props.texts.includes("rajaton") ? "" : <PriceTag>€/kpl</PriceTag>}
          </BoldFont>
          <LightFont>Viestit</LightFont>
        </CardItem>
        {props.speed === "0" ? (
          <CardItem>
            <BoldFont>Ei nettiä</BoldFont>
          </CardItem>
        ) : (
          <CardItem>
            <BoldFont>
              {props.speed}
              <PriceTag> Mbit/s</PriceTag>
            </BoldFont>
            <LightFont>{props.internet}</LightFont>
          </CardItem>
        )}
        <OrderButton>Tutustu ja tilaa</OrderButton>
      </Card>
    </Link>
  )
}

export default ResultCard
