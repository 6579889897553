import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import ResultCard from "../components/resultComponents/ResultCard"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import ArticleImage from "../components/images/ArticleImage"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"telia"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Telia/Sonera puhelinliittymä tarjoukset vuodella 2020 - Katso heti!"
        desc="Nappaa yksi markkinoiden edullisimmista rajattomista liittymistä Telia puhelinliittymä alkaen vain 22,90€. Tarjous voimassa vain rajoitetun ajan. Katso täältä! "
      />
      <Container>
        <Header1>
          Telia puhelinliittymä tarjous - Rajaton puhelinliittymä Telialta
          huippuedullisesti
        </Header1>
        <TextArea>
          <b>
            Telia tarjoaa nyt todella edullisesti{" "}
            <a href="/tilaa/teliarajaton100">Rajaton 100M</a> sekä{" "}
            <a href="/tilaa/teliarajaton100">Rajaton 200M </a>
            liittymiä
          </b>
          . Älä murehdi enään puhelinlaskustasi, vaan valitse alapuolelta
          sinulle sopiva paketti ja hyödynnä tarjous.
        </TextArea>
        <Header3>Tutustu alapuolelta Telian tarjouksiin</Header3>
        <AdserviceCompare />
        <RowContainer title={"Tutustu myös muihin operaattoreihin:"}>
          <Button link={"/elisa/"} title={"Elisa"} />
          <Button link={"/dna/"} title={"DNA"} />
          <Button link={"/moi/"} title={"Moi Mobiili"} />
        </RowContainer>
        <Header2>Telia – markkinoiden paras?</Header2>
        <TextArea>
          Telia on monikansallinen teleoperaattori, joka syntyi vuonna 2002
          ruotsalaisen Telian ja suomalaisen Soneran yhdistyessä. Vuonna 2003
          virallisesti perustetun Telian kotipaikka on Tukholmassa Ruotsissa ja
          se on listattu sekä Suomessa, että Ruotsissa pörssiin.
        </TextArea>
        <TextArea>
          Telia tarjoaa modernien teleoperaattoreiden tapaan luonnollisesti
          kiinteän verkon kuin mobiiliverkon palveluita niin kuluttaja- kuin
          yritysasiakkaille. Telia on levinnyt Pohjoismaiden ulkopuolelle myös
          esimerkiksi Isoon-Britanniaan sekä Keski-Eurooppaan ollen kuitenkin
          Pohjoismaiden ulkopuolella marginaalisessa roolissa.
        </TextArea>
        <Header3>Miksi valita Telia?</Header3>
        <TextArea>
          On fakta, että operaattorien väliset erot ovat nykypäivänä häviävän
          pieniä, ja moni asiakas valitsee operaattorinsa juuri saamansa
          palvelun mukaan. Telian tiedonsiirtonopeudet ovat erinomaiset ja se
          tarjoaa kattavan paketin erilaisia liittymiä erilaisiin
          käyttötarkoituksiin. Telian liittymissä EU- ja ETA-maiden käyttö
          kuuluu jokaiseen liittymäpakettiin, mikä on varsin poikkeuksellista
          liittymien saralla Suomessa. Telia tarjoaa lisäksi myös paketteja
          esimerkiksi Amerikkaan tai Aasiaan lähteville matkaajille, millä
          asiakas varmistaa edullisen netin myös Suomen rajojen ulkopuolella.
        </TextArea>
        <TextArea>
          Kuten totesimme, ainoastaan Telia tarjoaa EU- ja ETA-maiden käyttöä
          liittymän normaalilla kuukausihinnalla Suomessa. Telialla asiakas voi
          rakentaa oman liittymäpakettinsa yksittäisistä ominaisuuksista ja
          lisäpalveluista, joista koostuu asiakkaan puhelinliittymäkokonaisuus.
          Telia tarjoaakin perinteisten liittymien lisäksi myös erilaisia muita
          palveluita, kuten tietoturvia ja pilvipalveluita. Operaattorin
          sopimukset ovat nykyään myös määräaikaisia, mikä käytännössä
          tarkoittaa sitä, että sopimus on asiakkaan irtisanottavissa koska
          vaan, eikä liittymissä ole varsinaista sopimuskautta toisin kuin
          esimerkiksi useissa DNA:n liittymissä Suomessa.
        </TextArea>
        <TextArea>
          Telia onkin Suomen ainoa määräajaton operaattori, jonka liittymiin EU-
          ja ETA-käyttö kuuluu normaalilla kuukausihinnalla. Telialla asiakasta
          palvellaan kellon ympäri. Telian live chat -asiakaspalvelu on auki
          ympäri vuorokauden, joten asiakas saa koska tahansa apua mieltä
          askarruttaviin ongelmiin helposti ja vaivattomasti. Live chatin kautta
          asiakas voi myös päivittää omat palvelunsa tai vaikka pelkästään kysyä
          neuvoa. "Minun Telia" -sovellus kulkee asiakkaan mukana minne vain
          täydentäen Telian kattavan asiakaspalvelun. Telian asiakaspalvelu on
          erinomainen, sillä:
          <ul>
            <li>
              <a href="https://www.telia.fi/">Telia.fi:ssä</a> asiakas maksaa
              laskunsa helposti.
            </li>
            <li>Asiakastuen usein kysytyt kysymykset ovat asiakkaan apuna.</li>
            <li>Telia.fi-sivulta asiakas löytää lähimmän Telia Kaupan.</li>
            <li>Asiakas voi myös jättää yhteydenottopyynnön.</li>
          </ul>
        </TextArea>

        <TextArea>
          Telia on listattu sekä Ruotsissa että Suomessa pörssiin. Vaikka Telian
          pääkonttori on Tukholmassa, yhtiö on suomalainen ja se maksaa veronsa
          Suomeen. Telia työllistää yli 3 000 ihmistä Suomessa vuosittain osana
          Telia Companya, joka toimii nykyään peräti 17 eri maassa ympäri
          maailmaa. Kattavan yhteistyöverkon ansiosta yhtiö pystyykin tarjoamaan
          kattavat liittymäpalvelut asiakkailleen myös Pohjoismaiden
          ulkopuolella, mikä on suuri vahvuus juuri Telian liittymissä. Suomen
          liiketoimintaa hoitaa suomalainen osakeyhtiö nimeltä Telia Finland
          Oyj.
        </TextArea>
        <Header2>Millaisia liittymiä Telialla on?</Header2>
        <ArticleImage imgName="teliaLiittymat.jpg" />
        <TextArea>
          Telian isoin puhelinliittymäpaketti on{" "}
          <a href="/tilaa/teliarajaton200">Rajaton 450 M</a>. 450 megatavua
          nopeudeltaan oleva liittymä sisältää rajattoman netin Suomessa sekä
          Pohjolan ja Baltian maissa. Tämän lisäksi rajattomat puhelut ja
          viestit kuuluvat kuukausimaksuun. Ulkomaankäytön dataraja EU-maissa on
          20 gigatavua. Tämän lisäksi Telia puhelinliittymä tarjoaa myös Rajaton
          <a href="/tilaa/teliarajaton200">200 M</a> pakettia, joka on muuten
          sama paketti kuin 450 megatavuinen, mutta netti on hitaampi ja
          ulkomaankäytön dataraja on säädetty 15 gigatavuun. Muuten liittymä on
          viestien ja puheluiden osalta täysin sama.
        </TextArea>
        <TextArea>
          <a href="/tilaa/teliarajaton100">Rajaton 100 M -liittymä</a> on
          edellisten liittymien toisinto sillä erotuksella, että puhelut ja
          viestit eivät rajattomia, vaan niiden määrä on rajattu 1 200
          minuuttiin tai 1 200 kappaleeseen kuukausi tasolla. Kuten nimestä
          voikin jo päätellä, on liittymän internetnopeus 100 megatavua.
          Ulkomaankäytön datarajoitus on liittymässä 12 gigatavua. Rajaton Lite
          100 M -liittymä on edellisen liittymän kopio muuten, mutta puhelut ja
          viestit ovat tässä liittymässä maksullisia maksaen 0,07€ minuuttia tai
          kappaletta kohden. Ulkomaankäytön datarajoitus on 10 gigatavua.
        </TextArea>
        <TextArea>
          Tämän lisäksi Telialla on myös useita pienempiä puhelinliittymiä,
          jotka soveltuvat hyvin esimerkiksi lapsen ensimmäiseksi liittymäksi
          tai liittymäksi puhelimeen, jossa nettiyhteys ei ole välttämätön.
          Uuden asiakkaan onkin aina tärkeää selvittää tarpeensa, minkälaisella
          liittymälle olisi tarve. Käyttääkö asiakas nettiä ulkomailla? Kuinka
          nopea nettiyhteyden tulisi olla? Soittaako asiakas paljon tai
          lähettääkö hän tekstiviestejä? Minkälaisella laitteella liittymää
          olisi tarkoitus käyttää? Näiden kysymysten selvittyä on uuden
          liittymän hankkiminen huomattavasti aikaisempaa helpompaa, ja asiakas
          saa varmasti itselleen räätälöidyn tuotteen.
        </TextArea>
        <TextArea>
          Telia tarjoaa myös mobiililaajakaistoja. Mobiililaajakaistalla
          tarkoitetaan sitä, että käyttäjällä on nettitikku tai reititin, johon
          sim-kortti tulee. Sim-kortille on aktivoitu asiakkaan tarpeisiin
          perustuva liittymä. Telialla näitä liittymiä on useita. Esimerkkinä
          liittymistä Telia Rajaton 450 M, jossa 4G-netin nopeus on 450
          megatavua sisältäen rajattoman kotimaan, Pohjolan ja Baltian käytön.
          Liittymä lisäksi sisältää 20 gigatavua dataa ulkomaankäyttöä varten
          EU- ja ETA-maissa, mikäli asiakas matkustelee paljon.
          Mobiililaajakaista onkin kätevä, sillä pieni nettitikku tai reititin
          kulkee aina mukana.
        </TextArea>
        <Header3>Nettiyhteys kotiin</Header3>
        <ArticleImage imgName="telialaajakaista.jpg" />
        <TextArea>
          Telia tarjoaa luonnollisesti myös erilaisia{" "}
          <a
            href="https://www.telia.fi/kauppa/kodin-netti/netti-kotiin"
            rel="nofollow"
          >
            laajakaistayhteyksiä koteihin
          </a>
          . Niin sanottu kiinteä laajakaistayhteys tarkoittaa yhteyttä, jossa
          tietoliikenne kulkee asiakkaan modeemiin asti joko valokuituverkossa
          tai kupariverkossa. Internet-liittymiä on erilaisia, kuten
          kaapelimodeemiliittymiä, jossa modeemi kytketään kaapelitelevisioon ja
          DSL-liittymiä, jossa modeemi kytketään puhelinverkkoon. Useimmiten
          kaapelimodeemiyhteys on kuitenkin edullisin ratkaisu silloin kuin
          mobiililaajakaista jätetään pois laskuista ja keskitytään vain
          kiinteisiin laajakaistoihin. Kiinteä laajakaistayhteys tarvitsee
          luonnollisesti myös modeemin, johon yhteys aktivoidaan, paitsi silloin
          kun esimerkiksi taloyhtiössä verkkoratkaisu on Ethernet-yhteys.
        </TextArea>
        <TextArea>
          Onkin ensisijainen tärkeää selvittää, voidaanko asiakkaan osoitteeseen
          asentaa kiinteä laajakaistayhteys. Tämän jälkeen tulee selvittää, mikä
          asiakkaan tarve on. Kuinka nopeaa yhteyttä hän tarvitsee ja mitä
          netillä olisi tarkoitus tehdä? Mikäli asiakas kertoo esimerkiksi
          katsovansa paljon videoita HD-laadulla, on nopeuden oltava suurempi,
          kuin keskivertokäyttäjällä. Tämän jälkeen eri vaihtoehdot käydään
          läpi, ja asiakas saa parhaan vaihtoehdon. Usein paras mahdollinen
          yhteys määrittyy paitsi saatavuuden ja nopeuden mukaan, myös sen
          mukaan, miten monta laitetta asiakas haluaa kytkeä verkkoon.
        </TextArea>
        <TextArea>
          Telia tarjoaa ensimmäisenä operaattorina Suomessa niin sanotut
          eSim-kortit, jotka korvaavat perinteiset muoviset sim-kortit.
          ESim-kortti on siis nimensä mukaisesti täysin sähköinen sim-kortti,
          joka mahdollista useamman laitteen verkkoon kytkemisen samaan aikaan,
          eikä esimerkiksi puhelin ole enää täten fyysisesti riippuvainen
          esimerkiksi puhelin sim-korttipaikkojen määrästä. eSim-kortti on
          mahdollista kytkeä myös esimerkiksi älykelloon perinteisten puhelimien
          ja tablettien lisäksi. On kuitenkin otettava huomioon, että varsin
          uutena innovaatiota eSim-tuki on vasta tehty vain uusimmille Samsungin
          ja Applen laitteille.
        </TextArea>
        <TextArea>
          Telian eSim on mahdollista tilata kaikkiin Rajaton- ja Rajaton
          Lite-liittymiin. Telian asiakaspalvelussa, live chatissa tai Telia
          Kaupassa voi lisäksi päivittää vanhan mallisen sim-kortin uuteen
          eSimiin. Sähköisen sim-kortin etu on käytännössä se, että laitteista
          saadaan pidempi-ikäisiä, sillä niissä ei enää tarvitse olla paikkaa
          perinteiselle fyysiselle sim-kortille. eSim-kortti tarkoittaakin
          pähkinänkuoressa sitä, että esimerkiksi puhelimen ei enää tarvitse
          olla mukana, että asiakas saa täyden hyödyn irti vaikkapa älykellosta
          lähtiessä pois puhelimensa äärestä.
        </TextArea>
        <Header2>Telian tarjoukset</Header2>
        <ArticleImage imgName="teliatarjoukset.jpg" />
        <TextArea>
          Luonnollisesti Telia tarjoaa siinä missä mikä tahansa muukin
          operaattori myös asiakkailleen erilaisia tarjouksia. Nämä voivat
          liittyä esimerkiksi puhelinliittymiin tai laajakaistaliittymiin. Nämä
          tarjoukset voivat vaihdella myyntipisteestä riippuen, sillä
          esimerkiksi Telian verkkokaupassa, ständeillä tai myymälöissä on usein
          hieman eri hinnat. Telia tarjoaa myös erilaisia tarjouksia muihin
          palveluihin, kuten kanavapaketteihin ja puhelimiin. Laitetta tai
          palvelua ostaessa onkin ensiarvoisen tärkeää, että asiakas tutkii,
          mistä laitteen tai palvelun voisi ostaa edullisimmin, sillä se on
          asiakkaan etu.
        </TextArea>
        <TextArea>
          Usein tarjoukset ovat voimassa vain rajoitetun ajan tai niin kauan,
          kunnes tuotteita riittää ostettavissa. Telian tarjoukset ovat
          kuitenkin erittäin kattavia kattaen kaikki osa-alueet aina liittymistä
          kanavapaketteihin. Telia lisäksi tarjoaa liittymäasiakkailleen 3
          vuoden takuun kaikkiin puhelimeen ja tabletteihin ja avun ympäri
          vuorokauden. Nyrkkisääntönä voidaankin pitää, että hinnat vaihtelevat
          paikasta riippuen jonkun verran. Asiakkaan on kuitenkin syytä muistaa,
          että tarjoukset kattavat Telian kaikki palvelut, kunhan vain itse
          tuotetta on saatavilla. Telialla asiakas on tarjousten suhteen aina
          keskiössä:
          <ul>
            <li>Asiakas saa personoituja liittymätarjouksia.</li>
            <li>
              Telia Recycled-kierrätyspuhelimet ovat Telian asiakkaan etu.
            </li>
            <li>
              Telia TV:n asiakkaat saavat kuukauden bonuskanavan käyttöönsä.
            </li>
            <li>Tarjoukset ovat viikoittain vaihtuvia.</li>
          </ul>
        </TextArea>
        <TextArea>
          Telia on yksi tulevaisuuden palveluiden sanansaattajista. Telialla
          asiakas voi aktivoida käyttöönsä mobiilivarmenteen, mikä on
          käytännössä sähköinen henkilöllisyystunnus. Tämä onkin kätevää, sillä
          se korvaa perinteiset verkkopankkitunnistautumiset ja niiden
          salasanat. Telian mobiilivarmenne on myös erittäin turvallinen ja
          helppo todistaa henkilöllisyys, kun asiakas kirjautuu palveluihin.
          Telia tekeekin töitä sen eteen, että mobiilivarmenne tulisi ihmisten
          tietoisuuteen yhä enenevissä määrin, sillä kun palveluiden käyttö on
          helpompaa, jää asiakkaalle käytännössä enemmän aikaa niiden kanssa.
        </TextArea>
        <TextArea>
          Muita Telian tulevaisuuden teemoja ovat esimerkiksi asioiden internet
          sekä sähköinen taloushallinto sekä kirjanpito. Tulevaisuudessa kaikki
          laitteet tulevat olemaan yhteydessä verkkoon, ja keskustelemaan
          keskenään automaattisesti. Esimerkiksi asiakkaan puhelin voi olla
          yhteydessä hänen jääkaappiinsa. Telia kehittää tähän kehitykseen
          sopivia palveluita niin kuluttaja- kuin yritysasiakkaillekin. Telia
          tekee myös töitä laskujen sähköistämisen eteen, sillä taloushallinnon
          sähköistäminen on yksi Telian vahvoista teemoista. Telia lähettääkin
          vuosittain yli kymmenen miljoonaa laskua, joista iso osa jo nyt on
          uudenaikaisia sähköpostilaskuja.
        </TextArea>
        <Header4>Telia TV – mikä se on?</Header4>
        <ArticleImage imgName="teliatv.jpg" />
        <TextArea>
          <a href="https://tv.telia.fi/">Telia TV:n</a> kautta asiakas saa
          mukaansa digiboksin, joka tuo kodin viihdekeskuksen asiakkaan
          laitteille helposti. Telia TV mahdollistaa TV-ohjelmat asiakkaan
          laitteille niin suorana kuin tallenteina. Telia TV:n sovelluksen
          avulla käyttäjä voi tallentaa etänä haluamansa ohjelman vaikka
          bussimatkalla, ja katsoa sen sitten päästyään kotiin television
          ruudulta. Telia TV:n ohjelmakirjastot ovat erittäin kattavat sisältäen
          kaikki peruskanavat, ja kuuluupa palveluun myös elokuvavuokraamo,
          josta asiakas voi vuokrata elokuvia muutaman euron nimellistä
          vuokrahintaa vastaan.
        </TextArea>
        <TextArea>
          Telia TV-sovellus mahdollistaa palvelun tallennuksien hallinnan ja
          sisältöjen toistamisen missä vaan. Telia TV-palveluun asiakas voi
          peruskanavien lisäksi liittää myös maksukanavia, mikäli asiakas haluaa
          esimerkiksi katsoa Euroopan huippujalkapalloa tai tutkailla elokuvia
          ja uusimpia sarjoja Netflixin kautta. Telian TV-sovelluksen kautta
          asiakas voi lisäksi vuokrata elokuvia tai toistaa aikaisemmin
          tallentamiaan sisältöjä tabletilla tai älypuhelimella. Telialla on
          myös oikeudet kotimaiseen jääkiekon SM-liigaan, johon on mahdollista
          ostaa paketti, joka sisältää kaikki pelit, tai pelkästään oman
          joukkueensa pelit.
        </TextArea>
        <TextArea>
          Telia onkin pyrkinyt aktiivisesti mullistamaan operaattorikenttää
          omilla innovaatioillaan. eSim, Telia TV ja kuukausimaksuun kuuluva
          EU-käyttö ovat kaikki asioita, joita asiakkaat arvostavat. Telian
          asiakaspalvelu toimii kellon ympäri, ja ”Minun Telia” tuo
          asiakaspalvelun aina asiakkaan mukaan. Pitkällä aikavälillä johtavien
          operaattorien liittymissä ja palveluissa ei kuitenkaan ole isoja
          eroja, ja kysymys onkin lähinnä preferensseistä asiakkaan kohdalla.
          Haluaako asiakas katsoa SM-liigaa? Haittaako asiakasta se, että Telia
          on osin ruotsalainen? Antaako asiakas arvoa eSim-innovaatiolle?
        </TextArea>
        <TextArea>
          Operaattoreita usein vertaillaan juuri tiedonsiirtonopeuksien kautta,
          mutta nämä erot ovat todellisuudessa häviävän pieniä, ja kaikki
          riippuu käytännössä siitä, minkälaista palvelua asiakas saa ja miten
          operaattorin tarjoamat palvelut sopivat juuri hänen tarpeeseensa.
          Esimerkiksi Telia tarjoaa lisäpalveluita, kuten Telia Turvapaketti,
          Spotify, Mobiilivarmenne, Telia Freedome VPN, sekä vakuutukset
          laitteille tapaturman tai vikojen varalta. Näistä palveluista voi olla
          yllättävän paljonkin hyötyä asiakkaalle, ja asiakas voi jopa siirtää
          asiakkuutensa yhtiöön yksittäisten palveluiden takia.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Yhteenvetona voidaan sanoa, että Telia on laadukas ja kattava koko
          kansan teleoperaattori. Operaattorin palvelut ovat monipuoliset, ja ne
          kattavat asiakkaiden tarpeet kattavat aina puhelinliittymistä
          kiinteisiin laajakaistoihin mobiililaajakaistoja tietenkään
          unohtamatta.
        </TextArea>
        <TextArea>
          Telian verkko on kattava, ja sen asiakaspalvelu palvelee asiakasta
          kellon ympäri yhteydenottosyytä katsomatta. "Minun Telia" -sovellus
          kulkee asiakkaan mukana aina myös silloin, kun asiakas ei muuten pysty
          ottamaan Teliaan yhteyttä.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { img: { eq: "telia.jpg" } }) {
      nodes {
        benefits
        callPrice
        id
        img
        internetSpeed
        internet
        name
        price
        textPrice
        type
        url
        openFee
      }
    }
  }
`

export default FrontPage
